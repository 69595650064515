jQuery( document ).ready(function( $ ) {

	const alertToggle = document.querySelector('.v-site-alert__close');
	if ( alertToggle ) {
		alertToggle.addEventListener('click', function() {
			$('.v-site-alert').remove();
			sessionStorage.setItem('aaedc-site-alert', 'dismissed');
		});
	}

/* layout */

	$("#container.home #alpha, #container.home #gamma").matchHeight();
	$("#theta, #gamma").matchHeight();
	$("#omicron, #upsilon").matchHeight();
	$(".sector").matchHeight();
	$(".sector, .green").matchHeight();
	$("#community-blue, #community-map").matchHeight();
	$("#news-and-events .news").matchHeight();
	$(".footer-logo, .footer-links, .footer-contact, .footer-social").matchHeight();
	$("#alpha .leadership .leader").matchHeight();
	$("#alpha .members .member").matchHeight();
	$("#alpha .leadership .leader .info").matchHeight();
	$("#alpha .members .member .info").matchHeight();
	$("#alpha .business-parks-directory .business-park .info").matchHeight();
	$("dl.gallery-item").matchHeight();

/* hamburger */

	$('#hamburger').click(function() {
	$(this).toggleClass('open');
	$(".mobile-nav").toggleClass('open');
	});

	$('.mobile-subnav-main').click(function() {
	$('.mobile-subnav-menu').toggleClass('open');
	});

/* search button */

	$('#top-nav .aa-search.aa-search-button').click(function(){
		$('#top-nav form [type="submit"]').trigger('click');
	});

	$('.mobile-search .aa-search.aa-search-button').click(function(){
		$('.mobile-search form [type="submit"]').trigger('click');
	});

/* google analytics PDF document events */

	if( $('body').hasClass('page-id-751') ){

		$('#alpha ul#downloads li a').click(function(){
			var PDFname = $(this).text();
			ga('send', 'event', 'Research Documents Download', 'Click', PDFname);
		});

	}

/* google analytics - contact form subissions */


function clearMegaForm() {
  jQuery('fieldset.fieldset-cf7mls input').each(function(){
    var itemName = $(this).attr('name');
    Cookies.remove(itemName);
   });

   jQuery('fieldset.fieldset-cf7mls textarea').each(function(){
    var itemName = $(this).attr('name');
    Cookies.remove(itemName);
   });
}

document.addEventListener( 'wpcf7submit', function( event ) {
    var siteUrl = $('[name="site_url"]').val() || $('nav > a:first-child').attr('href');

    if ( '70' == event.detail.contactFormId ){
        ga('send', 'event', 'Contact Form Submission', 'Click', 'Contact Form');
    }

    if ( '740' == event.detail.contactFormId ){
        ga('send', 'event', 'Contact Form Submission', 'Click', 'Information Request Form');
    }

    if ( '2349' == event.detail.contactFormId ){
        ga('send', 'event', 'Contact Form Submission', 'Click', 'Interior CTA Form');
    }

    if ( '744' == event.detail.contactFormId ){
        ga('send', 'event', 'Contact Form Submission', 'Click', 'Tell Us About Your Business Form');
    }

    if ( '3103' == event.detail.contactFormId ){
        ga('send', 'event', 'Contact Form Submission', 'Click', 'Runway To Success');
    }

}, false );

document.addEventListener( 'wpcf7mailsent', function( event ) {
  var siteUrl = $('[name="site_url"]').val() || $('nav > a:first-child').attr('href');

  if ( '70' == event.detail.contactFormId ){
    window.location = siteUrl + '/contact/submission-successful/';
  }

  if ( '744' == event.detail.contactFormId ){
    window.location = siteUrl + '/contact/submission-successful/';
  }

  if ('740' == event.detail.contactFormId) {
    window.location = siteUrl + '/contact/market-research-documents/';
  }

  if ('893' == event.detail.contactFormId) {
    window.location = siteUrl + '/contact/submission-successful/';
  }

  if ('3103' == event.detail.contactFormId) {
    clearMegaForm();
    window.location = siteUrl + '/business/do-business-here/travel-awards/runway-to-success-application/thank-you/';
  }
}, false);

document.addEventListener('wpcf7spam', function(e) {
  if ('3103' == event.detail.contactFormId) {
    clearMegaForm();
    window.location = siteUrl + '/business/do-business-here/travel-awards/runway-to-success-application/error/';
  }
});

document.addEventListener('wpcf7mailfailed', function(e) {
  if ('3103' == event.detail.contactFormId) {
    clearMegaForm();
    window.location = siteUrl + '/business/do-business-here/travel-awards/runway-to-success-application/error/';
  }
});

$('.v-form [type="checkbox"]').each(function(){
	console.log($(this));
	$(this).addClass('v-form__checkbox');
	$('<span class="v-form__checkbox-styled">').insertAfter($(this));
});

$('.v-form [type="radio"]').each(function(){
	$(this).addClass('v-form__radio');
	$('<span class="v-form__radio-styled">').insertAfter($(this));
});

$('.v-form select').each(function(){
	$(this).wrap('<span class="v-form__select">');
});

$('.v-form__date').each(function(){
	var picker = new Pikaday({ field: this, format: 'MM/DD/YYYY' });
});

});
